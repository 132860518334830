import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTimeout } from '../../hooks';

import {
  fetchUserInfo,
  selectTimeLeftForExpiry,
  selectIsLoggedIn,
  selectIsCompleted,
  selectIsFailed,
  selectIsNotStarted,
  selectUserRoles,
} from '../../core/reducers/authSlice';
import { API_BASEURL } from '../../config';
import { clearCsrfToken } from '../../utils/csrf-token';

const AuthHandler = ({ children }) => {
  const dispatch = useDispatch();
  const timeLeftForExpiry = useSelector(selectTimeLeftForExpiry);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isCompleted = useSelector(selectIsCompleted);
  const isFailed = useSelector(selectIsFailed);
  const isNotStarted = useSelector(selectIsNotStarted);
  const roles = useSelector(selectUserRoles);
  console.log({ roles });
  useEffect(() => {
    sessionStorage.setItem('persona_role', roles);
  }, [roles]);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchUserInfo());
  }, [dispatch]);

  useTimeout(() => {
    (async () => {
      await clearCsrfToken();
    })();
    window.location.href = `${API_BASEURL}/logout`;
  }, timeLeftForExpiry);
  useEffect(() => {
    if (!isLoggedIn && (!isNotStarted || isFailed)) {
      (async () => {
        await clearCsrfToken();
      })();
      window.location.href = `${API_BASEURL}/login`;
    }
  }, [isLoggedIn, isNotStarted, isFailed, dispatch]);
  useEffect(() => {
    if (isNotStarted && !isLoggedIn && (isCompleted || isFailed)) {
      navigate(`/error?error_code=UNAUTHORIZED_ERROR`);
    }
  }, [isNotStarted, isLoggedIn, isCompleted, isFailed, navigate]);

  if (isLoggedIn) return <> { children } </>;
  return null;
};

AuthHandler.propTypes = {
  children: PropTypes.any,
};

export default AuthHandler;
