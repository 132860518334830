import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import billingReviewAPI from './billingReviewAPI';

export const fetchBillingSummary = createAsyncThunk(
  'billingReview/fetchBillingSummary',
  async ({ partnerOOID, invoiceId }, { getState, rejectWithValue }) => {
    const { loading } = getState().billingReview.requests.fetchBillingSummary;
    if (loading !== 'pending') {
      return;
    }
    try {
      const response = await billingReviewAPI.getBillingSummary({
        partnerOOID,
        invoiceId,
      });
      return response.content;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const submitLeadChanges = createAsyncThunk(
  'billingReview/submitLeadChanges',
  async ({ invoiceId, leadId, payload }, { getState, rejectWithValue }) => {
    const { loading } = getState().billingReview.requests.submitLeadChanges;
    if (loading !== 'pending') {
      return;
    }
    try {
      const response = await billingReviewAPI.postLeadChanges({
        invoiceId,
        leadId,
        payload,
      });
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchBillingADPSummary = createAsyncThunk(
  'billingReview/fetchBillingADPSummary',
  async (_, { getState, rejectWithValue }) => {
    const { loading } =
      getState().billingReview.requests.fetchBillingADPSummary;
    if (loading !== 'pending') {
      return;
    }
    try {
      const response = await billingReviewAPI.getBillingADPSummary();
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const submitInvoiceForReviewOrApproval = createAsyncThunk(
  'billingReview/submitInvoiceForReviewOrApproval',
  async ({ invoiceId, role, approved }, { getState, rejectWithValue }) => {
    const { loading } =
      getState().billingReview.requests.submitInvoiceForReviewOrApproval;
    if (loading !== 'pending') {
      return;
    }
    try {
      const response = await billingReviewAPI.reviewOrApproveInvoice({
        invoiceId,
        role,
        approved,
      });
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const submitLeadTermination = createAsyncThunk(
  'billingReview/submitLeadTermination',
  async ({ invoiceId, leadId, comment }, { getState, rejectWithValue }) => {
    const { loading } = getState().billingReview.requests.submitLeadTermination;
    if (loading !== 'pending') {
      return;
    }
    try {
      const response = await billingReviewAPI.terminateLead({
        invoiceId,
        leadId,
        comment,
      });
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const submitLeadUnTermination = createAsyncThunk(
  'billingReview/submitLeadUnTermination',
  async ({ invoiceId, leadId, comment }, { getState, rejectWithValue }) => {
    const { loading } =
      getState().billingReview.requests.submitLeadUnTermination;
    if (loading !== 'pending') {
      return;
    }
    try {
      const response = await billingReviewAPI.unTerminateLead({
        invoiceId,
        leadId,
        comment,
      });
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchChangeDetail = createAsyncThunk(
  'billingReview/fetchChangeDetail',
  async (leadId, { getState, rejectWithValue }) => {
    const { loading } = getState().billingReview.requests.fetchChangeDetail;
    if (loading !== 'pending') {
      return;
    }
    try {
      const response = await billingReviewAPI.getChangeDetail(leadId);
      return response.content;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  data: null,
  adpData: null,
  changeDetail: null,
  requests: {
    fetchBillingSummary: {
      loading: 'idle',
      error: null,
    },
    submitLeadChanges: {
      loading: 'idle',
      error: null,
    },
    fetchBillingADPSummary: {
      loading: 'idle',
      error: null,
    },
    submitLeadTermination: {
      loading: 'idle',
      error: null,
    },
    submitLeadUnTermination: {
      loading: 'idle',
      error: null,
    },
    submitInvoiceForReviewOrApproval: {
      loading: 'idle',
      error: null,
    },
    fetchChangeDetail: {
      loading: 'idle',
      error: null,
    },
  },
};

export const slice = createSlice({
  name: 'billingReview',
  initialState,
  reducers: {
    resetBillingSummary(state) {
      state.data = null;
      state.requests.fetchBillingSummary.loading = 'idle';
    },
    resetBillingADPSummary(state) {
      state.adpData = null;
      state.requests.fetchBillingADPSummary.loading = 'idle';
    },
    resetSubmitLeadChanges(state) {
      state.requests.submitLeadChanges.loading = 'idle';
    },
    resetSubmitLeadTermination(state) {
      state.requests.submitLeadTermination.loading = 'idle';
    },
    resetSubmitLeadUnTermination(state) {
      state.requests.submitLeadUnTermination.loading = 'idle';
    },
    resetSubmitInvoiceForReviewOrApproval(state) {
      state.requests.submitInvoiceForReviewOrApproval.loading = 'idle';
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchBillingSummary.pending, (state, _action) => {
      if (
        state.requests.fetchBillingSummary.loading === 'idle' ||
        state.requests.fetchBillingSummary.loading === 'succeeded'
      ) {
        state.requests.fetchBillingSummary.loading = 'pending';
      }
    });
    builder.addCase(fetchBillingSummary.fulfilled, (state, action) => {
      if (state.requests.fetchBillingSummary.loading === 'pending') {
        state.requests.fetchBillingSummary.loading = 'succeeded';
        state.data = action.payload;
      }
    });
    builder.addCase(fetchBillingSummary.rejected, (state, action) => {
      if (state.requests.fetchBillingSummary.loading === 'pending') {
        state.requests.fetchBillingSummary.loading = 'failed';
        state.requests.fetchBillingSummary.error = action.error;
      }
    });
    builder.addCase(submitLeadChanges.pending, (state, _action) => {
      if (
        state.requests.submitLeadChanges.loading === 'idle' ||
        state.requests.submitLeadChanges.loading === 'succeeded'
      ) {
        state.requests.submitLeadChanges.loading = 'pending';
      }
    });
    builder.addCase(submitLeadChanges.fulfilled, (state, action) => {
      if (state.requests.submitLeadChanges.loading === 'pending') {
        state.requests.submitLeadChanges.loading = 'succeeded';
        // state.data = action.payload;
      }
    });
    builder.addCase(submitLeadChanges.rejected, (state, action) => {
      if (state.requests.submitLeadChanges.loading === 'pending') {
        state.requests.submitLeadChanges.loading = 'failed';
        state.requests.submitLeadChanges.error = action.error;
      }
    });
    builder.addCase(fetchBillingADPSummary.pending, (state, _action) => {
      if (
        state.requests.fetchBillingADPSummary.loading === 'idle' ||
        state.requests.fetchBillingADPSummary.loading === 'succeeded'
      ) {
        state.requests.fetchBillingADPSummary.loading = 'pending';
      }
    });
    builder.addCase(fetchBillingADPSummary.fulfilled, (state, action) => {
      if (state.requests.fetchBillingADPSummary.loading === 'pending') {
        state.requests.fetchBillingADPSummary.loading = 'succeeded';
        state.adpData = action.payload;
      }
    });
    builder.addCase(fetchBillingADPSummary.rejected, (state, action) => {
      if (state.requests.fetchBillingADPSummary.loading === 'pending') {
        state.requests.fetchBillingADPSummary.loading = 'failed';
        state.requests.fetchBillingADPSummary.error = action.error;
      }
    });
    builder.addCase(submitLeadTermination.pending, (state, _action) => {
      if (
        state.requests.submitLeadTermination.loading === 'idle' ||
        state.requests.submitLeadTermination.loading === 'succeeded'
      ) {
        state.requests.submitLeadTermination.loading = 'pending';
      }
    });
    builder.addCase(submitLeadTermination.fulfilled, (state, action) => {
      if (state.requests.submitLeadTermination.loading === 'pending') {
        state.requests.submitLeadTermination.loading = 'succeeded';
        // state.data = action.payload;
      }
    });
    builder.addCase(submitLeadTermination.rejected, (state, action) => {
      if (state.requests.submitLeadTermination.loading === 'pending') {
        state.requests.submitLeadTermination.loading = 'failed';
        state.requests.submitLeadTermination.error = action.error;
      }
    });
    builder.addCase(submitLeadUnTermination.pending, (state, _action) => {
      if (
        state.requests.submitLeadUnTermination.loading === 'idle' ||
        state.requests.submitLeadUnTermination.loading === 'succeeded'
      ) {
        state.requests.submitLeadUnTermination.loading = 'pending';
      }
    });
    builder.addCase(submitLeadUnTermination.fulfilled, (state, action) => {
      if (state.requests.submitLeadUnTermination.loading === 'pending') {
        state.requests.submitLeadUnTermination.loading = 'succeeded';
        // state.data = action.payload;
      }
    });
    builder.addCase(submitLeadUnTermination.rejected, (state, action) => {
      if (state.requests.submitLeadUnTermination.loading === 'pending') {
        state.requests.submitLeadUnTermination.loading = 'failed';
        state.requests.submitLeadUnTermination.error = action.error;
      }
    });
    builder.addCase(
      submitInvoiceForReviewOrApproval.pending,
      (state, _action) => {
        if (
          state.requests.submitInvoiceForReviewOrApproval.loading === 'idle' ||
          state.requests.submitInvoiceForReviewOrApproval.loading ===
            'succeeded'
        ) {
          state.requests.submitInvoiceForReviewOrApproval.loading = 'pending';
        }
      }
    );
    builder.addCase(
      submitInvoiceForReviewOrApproval.fulfilled,
      (state, action) => {
        if (
          state.requests.submitInvoiceForReviewOrApproval.loading === 'pending'
        ) {
          state.requests.submitInvoiceForReviewOrApproval.loading = 'succeeded';
          // state.data = action.payload;
        }
      }
    );
    builder.addCase(
      submitInvoiceForReviewOrApproval.rejected,
      (state, action) => {
        if (
          state.requests.submitInvoiceForReviewOrApproval.loading === 'pending'
        ) {
          state.requests.submitInvoiceForReviewOrApproval.loading = 'failed';
          state.requests.submitInvoiceForReviewOrApproval.error = action.error;
        }
      }
    );
    builder.addCase(fetchChangeDetail.pending, (state, _action) => {
      if (
        state.requests.fetchChangeDetail.loading === 'idle' ||
        state.requests.fetchChangeDetail.loading === 'succeeded'
      ) {
        state.requests.fetchChangeDetail.loading = 'pending';
      }
    });
    builder.addCase(fetchChangeDetail.fulfilled, (state, action) => {
      if (state.requests.fetchChangeDetail.loading === 'pending') {
        state.requests.fetchChangeDetail.loading = 'succeeded';
        state.changeDetail = action.payload;
      }
    });
    builder.addCase(fetchChangeDetail.rejected, (state, action) => {
      if (state.requests.fetchChangeDetail.loading === 'pending') {
        state.requests.fetchChangeDetail.loading = 'failed';
        state.requests.fetchChangeDetail.error = action.error;
      }
    });
  },
});

export const {
  resetSubmitLeadChanges,
  resetSubmitLeadTermination,
  resetSubmitLeadUnTermination,
  resetSubmitInvoiceForReviewOrApproval,
} = slice.actions;

const reducer = slice.reducer;
export default reducer;

export const { resetBillingSummary, resetBillingADPSummary } = slice.actions;

export const selectBillingReview = state => state.billingReview;

export const selectBillingReviewRequests = createSelector(
  selectBillingReview,
  s => s.requests
);

export const selectBillingReviewFetchBillingSummaryRequest = createSelector(
  selectBillingReviewRequests,
  s => s.fetchBillingSummary
);

export const selectBillingReviewSubmitLeadChangeRequest = createSelector(
  selectBillingReviewRequests,
  s => s.submitLeadChanges
);

export const selectBillingReviewFetchBillingADPSummaryRequest = createSelector(
  selectBillingReviewRequests,
  s => s.fetchBillingADPSummary
);

export const selectBillingReviewSubmitLeadTerminationRequest = createSelector(
  selectBillingReviewRequests,
  s => s.submitLeadTermination
);
export const selectBillingReviewSubmitLeadUnTerminationRequest = createSelector(
  selectBillingReviewRequests,
  s => s.submitLeadUnTermination
);

export const selectBillingReviewSubmitInvoiceForReviewOrApprovalRequest =
  createSelector(
    selectBillingReviewRequests,
    s => s.submitInvoiceForReviewOrApproval
  );

export const selectBillingReviewFetchChangeDetailRequest = createSelector(
  selectBillingReviewRequests,
  s => s.fetchChangeDetail
);

export const selectFetchBillingSummaryRequestIsNotStarted = createSelector(
  selectBillingReviewFetchBillingSummaryRequest,
  s => !!(s.loading === 'idle')
);

export const selectFetchBillingSummaryRequestIsLoading = createSelector(
  selectBillingReviewFetchBillingSummaryRequest,
  s => !!(s.loading === 'pending')
);

export const selectFetchBillingSummaryRequestIsCompleted = createSelector(
  selectBillingReviewFetchBillingSummaryRequest,
  s => !!(s.loading === 'succeeded')
);

export const selectFetchBillingSummaryRequestIsFailed = createSelector(
  selectBillingReviewFetchBillingSummaryRequest,
  s => !!(s.loading === 'failed')
);

export const selectFetchBillingSummaryRequestError = createSelector(
  selectBillingReviewFetchBillingSummaryRequest,
  s => s.error
);

export const selectSubmitLeadChangeRequestIsNotStarted = createSelector(
  selectBillingReviewSubmitLeadChangeRequest,
  s => !!(s.loading === 'idle')
);

export const selectSubmitLeadChangeRequestIsLoading = createSelector(
  selectBillingReviewSubmitLeadChangeRequest,
  s => !!(s.loading === 'pending')
);

export const selectSubmitLeadChangeRequestIsCompleted = createSelector(
  selectBillingReviewSubmitLeadChangeRequest,
  s => !!(s.loading === 'succeeded')
);

export const selectSubmitLeadChangeRequestIsFailed = createSelector(
  selectBillingReviewSubmitLeadChangeRequest,
  s => !!(s.loading === 'failed')
);

export const selectSubmitLeadChangeRequestError = createSelector(
  selectBillingReviewSubmitLeadChangeRequest,
  s => s.error
);

export const selectFetchBillingADPSummaryRequestIsNotStarted = createSelector(
  selectBillingReviewFetchBillingADPSummaryRequest,
  s => !!(s.loading === 'idle')
);

export const selectFetchBillingADPSummaryRequestIsLoading = createSelector(
  selectBillingReviewFetchBillingADPSummaryRequest,
  s => !!(s.loading === 'pending')
);

export const selectFetchBillingADPSummaryRequestIsCompleted = createSelector(
  selectBillingReviewFetchBillingADPSummaryRequest,
  s => !!(s.loading === 'succeeded')
);

export const selectFetchBillingADPSummaryRequestIsFailed = createSelector(
  selectBillingReviewFetchBillingADPSummaryRequest,
  s => !!(s.loading === 'failed')
);

export const selectFetchBillingADPSummaryRequestError = createSelector(
  selectBillingReviewFetchBillingADPSummaryRequest,
  s => s.error
);

export const selectSubmitLeadTerminationRequestIsNotStarted = createSelector(
  selectBillingReviewSubmitLeadTerminationRequest,
  s => !!(s.loading === 'idle')
);

export const selectSubmitLeadTerminationRequestIsLoading = createSelector(
  selectBillingReviewSubmitLeadTerminationRequest,
  s => !!(s.loading === 'pending')
);

export const selectSubmitLeadTerminationRequestIsCompleted = createSelector(
  selectBillingReviewSubmitLeadTerminationRequest,
  s => !!(s.loading === 'succeeded')
);

export const selectSubmitLeadTerminationRequestIsFailed = createSelector(
  selectBillingReviewSubmitLeadTerminationRequest,
  s => !!(s.loading === 'failed')
);

export const selectSubmitLeadTerminationRequestError = createSelector(
  selectBillingReviewSubmitLeadTerminationRequest,
  s => s.error
);
export const selectSubmitLeadUnTerminationRequestIsNotStarted = createSelector(
  selectBillingReviewSubmitLeadUnTerminationRequest,
  s => !!(s.loading === 'idle')
);

export const selectSubmitLeadUnTerminationRequestIsLoading = createSelector(
  selectBillingReviewSubmitLeadUnTerminationRequest,
  s => !!(s.loading === 'pending')
);

export const selectSubmitLeadUnTerminationRequestIsCompleted = createSelector(
  selectBillingReviewSubmitLeadUnTerminationRequest,
  s => !!(s.loading === 'succeeded')
);

export const selectSubmitLeadUnTerminationRequestIsFailed = createSelector(
  selectBillingReviewSubmitLeadUnTerminationRequest,
  s => !!(s.loading === 'failed')
);

export const selectSubmitLeadUnTerminationRequestError = createSelector(
  selectBillingReviewSubmitLeadUnTerminationRequest,
  s => s.error
);

export const selectSubmitInvoiceForReviewOrApprovalRequestIsNotStarted =
  createSelector(
    selectBillingReviewSubmitInvoiceForReviewOrApprovalRequest,
    s => !!(s.loading === 'idle')
  );

export const selectSubmitInvoiceForReviewOrApprovalRequestIsLoading =
  createSelector(
    selectBillingReviewSubmitInvoiceForReviewOrApprovalRequest,
    s => !!(s.loading === 'pending')
  );

export const selectSubmitInvoiceForReviewOrApprovalRequestIsCompleted =
  createSelector(
    selectBillingReviewSubmitInvoiceForReviewOrApprovalRequest,
    s => !!(s.loading === 'succeeded')
  );

export const selectSubmitInvoiceForReviewOrApprovalRequestIsFailed =
  createSelector(
    selectBillingReviewSubmitInvoiceForReviewOrApprovalRequest,
    s => !!(s.loading === 'failed')
  );

export const selectSubmitInvoiceForReviewOrApprovalRequestError =
  createSelector(
    selectBillingReviewSubmitInvoiceForReviewOrApprovalRequest,
    s => s.error
  );

export const selectFetchChangeDetailRequestIsNotStarted = createSelector(
  selectBillingReviewFetchChangeDetailRequest,
  s => !!(s.loading === 'idle')
);

export const selectFetchChangeDetailRequestIsLoading = createSelector(
  selectBillingReviewFetchChangeDetailRequest,
  s => !!(s.loading === 'pending')
);

export const selectFetchChangeDetailRequestIsCompleted = createSelector(
  selectBillingReviewFetchChangeDetailRequest,
  s => !!(s.loading === 'succeeded')
);

export const selectFetchChangeDetailRequestIsFailed = createSelector(
  selectBillingReviewFetchChangeDetailRequest,
  s => !!(s.loading === 'failed')
);

export const selectFetchChangeDetailRequestError = createSelector(
  selectBillingReviewFetchChangeDetailRequest,
  s => s.error
);

export const selectBillingReviewData = createSelector(
  selectBillingReview,
  s => s.data
);

export const selectBillingReviewInvoiceId = createSelector(
  selectBillingReview,
  s => s.data?.invoiceId
);

export const selectIsAlreadyPartnerReviewed = createSelector(
  selectBillingReviewData,
  s => !!s.partnerReviewedOn
);

export const selectIsAlreadyAccountsReceivableReviewed = createSelector(
  selectBillingReviewData,
  s => !!s.accountsReceivableReviewedOn
);

export const selectIsAlreadyBillingAdminApprovedOn = createSelector(
  selectBillingReviewData,
  s => !!s.billingAdminApprovedOn
);

export const selectBillingReviewInvoiceStartDate = createSelector(
  selectBillingReview,
  s => s.data?.invoiceStartDate
);

export const selectBillingReviewInvoiceEndDate = createSelector(
  selectBillingReview,
  s => s.data?.invoiceEndDate
);

export const selectBillingReviewADPData = createSelector(
  selectBillingReview,
  s => s.adpData
);

export const selectBillingReviewLeads = createSelector(
  selectBillingReview,
  s => s.data?.leads
);

export const selectBillingReviewLeadById = leadId =>
  createSelector(selectBillingReviewLeads, s =>
    s.find(l => l.leadId === leadId)
  );

export const selectBillingReviewNewLeads = createSelector(
  selectBillingReviewLeads,
  selectBillingReviewInvoiceEndDate,
  (s, d) =>
    s?.filter(l => {
      const invoiceEndDate = new Date(d);
      const startDate = new Date(l.startDate);
      return (
        invoiceEndDate.getUTCFullYear() === startDate.getUTCFullYear() &&
        invoiceEndDate.getUTCMonth() === startDate.getUTCMonth() &&
        startDate <= invoiceEndDate &&
        l.status !== 'DELETED'
      );
    })
);

export const selectBillingReviewPreviousLeads = createSelector(
  selectBillingReviewLeads,
  selectBillingReviewInvoiceStartDate,
  (s, d) =>
    s?.filter(l => {
      const startDate = new Date(l.startDate);
      const invoiceStartDate = new Date(d);
      return startDate < invoiceStartDate && l.status !== 'DELETED';
    })
);

export const selectBillingReviewNewLeadsTotalAmount = createSelector(
  selectBillingReviewNewLeads,
  s =>
    s?.reduce((acc, l) => {
      return acc + l.revShareDue;
    }, 0)
);

export const selectBillingReviewAllLeadsWithChanges = createSelector(
  selectBillingReviewLeads,
  s => s?.filter(l => l.changes.length > 0)
);

export const selectBillingReviewPreviousLeadsRowData = createSelector(
  selectBillingReviewPreviousLeads,
  s => {
    const rowData = [];
    const initialAcc = {
      previousLeadsCount: 0,
      cumulativeRevShareDue: 0,
      cumulativeCav: 0,
      cumulativeAdpRevenueSharePercent: 0,
    };
    if (s?.length > 0) {
      const cumulativeData = s.reduce((acc, l) => {
        return {
          previousLeadsCount: acc.previousLeadsCount + 1,
          cumulativeCav: acc.cumulativeCav + l.cav,
          cumulativeAdpRevenueSharePercent:
            acc.cumulativeAdpRevenueSharePercent + l.adpRevenueSharePercent,
          cumulativeRevShareDue: acc.cumulativeRevShareDue + l.revShareDue,
        };
      }, initialAcc);
      cumulativeData.cumulativeAdpRevenueSharePercent =
        cumulativeData.cumulativeAdpRevenueSharePercent / s.length;
      rowData.push(cumulativeData);
    }

    return rowData;
  }
);

export const selectBillingReviewAllLeadsTotalAmount = createSelector(
  selectBillingReviewNewLeads,
  selectBillingReviewPreviousLeads,
  (n, p) =>
    [...(n || []), ...(p || [])]?.reduce((acc, l) => {
      return acc + l.revShareDue;
    }, 0)
);

export const selectBillingReviewDelayedLeads = createSelector(
  selectBillingReviewLeads,
  selectBillingReviewInvoiceEndDate,
  (s, d) =>
    s?.filter(l => {
      const invoiceEndDate = new Date(d);
      const startDate = new Date(l.startDate);
      return startDate > invoiceEndDate && l.status !== 'DELETED';
    })
);

export const selectBillingReviewTerminatedLeads = createSelector(
  selectBillingReviewLeads,
  s => s?.filter(l => l.status === 'DELETED')
);

export const selectBillingReviewChangeDetail = createSelector(
  selectBillingReview,
  s => s.changeDetail
);
