import React from 'react';
import { SdfAlert, SdfLink } from '@synerg/react-components';
import { useSelector } from 'react-redux';
import {
  selectIsPartner,
  selectUserOrgName,
} from '../../core/reducers/authSlice';
import * as styles from './FeedbackFooter.styles.js';
import { selectAppSettingsData } from '../../core/reducers/appSettingsSlice';

export const FeedbackFooter = () => {
  const isPartner = useSelector(selectIsPartner);
  const userOrgName = useSelector(selectUserOrgName);
  const appSettingsData = useSelector(selectAppSettingsData);

  if (!isPartner) return null;

  return (
    <div className={ styles.feedbackFooter }>
      <SdfAlert status="info">
        <div className={ styles.feedbackAlert }>
          <span className="text-body-02">
            Tell us what you think of the app! Share with us how we can improve
            the experience for you.
          </span>
          <SdfLink
            emphasis="secondary"
            href={ `mailto:${
              appSettingsData && appSettingsData.CONTACT_MAILTO_ADDRESS
            }?subject=${userOrgName} - Revenue Share Portal Feedback` }
          >
            Provide feedback
          </SdfLink>
        </div>
      </SdfAlert>
    </div>
  );
};

export default FeedbackFooter;
