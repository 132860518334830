import { classnames } from 'tailwindcss-classnames';

export const feedbackFooter = classnames(
  'relative',
  'bottom-0',
  'min-w-screen',
  'z-10'
);

export const feedbackAlert = classnames(
  'flex',
  'justify-between',
  'items-center',
  'min-w-full'
);
