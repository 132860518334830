import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import billingSetupAPI from './billingSetupAPI';

export const fetchPartnerList = createAsyncThunk(
  'billingSetup/fetchPartnerList',
  async (_, { getState, rejectWithValue }) => {
    const { loading } = getState().billingSetup.requests.fetchPartnerList;
    if (loading !== 'pending') {
      return;
    }
    try {
      const response = await billingSetupAPI.fetchPartnerList();
      return response.content;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const deletePartner = createAsyncThunk(
  'billingSetup/deletePartner',
  async ({ partnerOOID }, { getState, rejectWithValue }) => {
    const { loading } = getState().billingSetup.requests.deletePartner;
    if (loading !== 'pending') {
      return;
    }
    try {
      const response = await billingSetupAPI.deletePartner({ partnerOOID });
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const addPartner = createAsyncThunk(
  'billingSetup/addPartner',
  async (
    { partnerName, partnerOOID, peCode },
    { getState, rejectWithValue }
  ) => {
    const { loading } = getState().billingSetup.requests.addPartner;
    if (loading !== 'pending') {
      return;
    }
    try {
      const response = await billingSetupAPI.addPartner({
        partnerName,
        partnerOOID,
        peCode,
      });
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  data: null,
  requests: {
    fetchPartnerList: {
      loading: 'idle',
      error: null,
    },
    deletePartner: {
      loading: 'idle',
      error: null,
    },
    addPartner: {
      loading: 'idle',
      error: null,
    },
  },
};

export const slice = createSlice({
  name: 'billingSetup',
  initialState,
  reducers: {
    resetPartnerList(state) {
      state.data = null;
      state.requests.fetchPartnerList.loading = 'idle';
    },
    resetDeletePartner(state) {
      state.requests.deletePartner.loading = 'idle';
    },
    resetAddPartner(state) {
      state.requests.addPartner.loading = 'idle';
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchPartnerList.pending, (state, action) => {
      if (
        state.requests.fetchPartnerList.loading === 'idle' ||
        state.requests.fetchPartnerList.loading === 'succeeded'
      ) {
        state.requests.fetchPartnerList.loading = 'pending';
      }
    });
    builder.addCase(fetchPartnerList.fulfilled, (state, action) => {
      if (state.requests.fetchPartnerList.loading === 'pending') {
        state.requests.fetchPartnerList.loading = 'succeeded';
        state.data = action.payload;
      }
    });
    builder.addCase(fetchPartnerList.rejected, (state, action) => {
      if (state.requests.fetchPartnerList.loading === 'pending') {
        state.requests.fetchPartnerList.loading = 'failed';
        state.requests.fetchPartnerList.error = action.error;
      }
    });
    builder.addCase(deletePartner.pending, (state, action) => {
      if (
        state.requests.deletePartner.loading === 'idle' ||
        state.requests.deletePartner.loading === 'succeeded'
      ) {
        state.requests.deletePartner.loading = 'pending';
      }
    });
    builder.addCase(deletePartner.fulfilled, (state, action) => {
      if (state.requests.deletePartner.loading === 'pending') {
        state.requests.deletePartner.loading = 'succeeded';
      }
    });
    builder.addCase(deletePartner.rejected, (state, action) => {
      if (state.requests.deletePartner.loading === 'pending') {
        state.requests.deletePartner.loading = 'failed';
        state.requests.deletePartner.error = action.error;
      }
    });
    builder.addCase(addPartner.pending, (state, action) => {
      if (
        state.requests.addPartner.loading === 'idle' ||
        state.requests.addPartner.loading === 'succeeded'
      ) {
        state.requests.addPartner.loading = 'pending';
      }
    });
    builder.addCase(addPartner.fulfilled, (state, action) => {
      if (state.requests.addPartner.loading === 'pending') {
        state.requests.addPartner.loading = 'succeeded';
      }
    });
    builder.addCase(addPartner.rejected, (state, action) => {
      if (state.requests.addPartner.loading === 'pending') {
        state.requests.addPartner.loading = 'failed';
        state.requests.addPartner.error = action.error;
      }
    });
  },
});

const reducer = slice.reducer;
export default reducer;

export const { resetPartnerList, resetDeletePartner, resetAddPartner } =
  slice.actions;

export const selectBillingSetup = state => state.billingSetup;

export const selectBillingSetupRequests = createSelector(
  selectBillingSetup,
  s => s.requests
);

export const selectFetchPartnerListRequest = createSelector(
  selectBillingSetupRequests,
  s => s.fetchPartnerList
);

export const selectDeletePartnerRequest = createSelector(
  selectBillingSetupRequests,
  s => s.deletePartner
);

export const selectAddPartnerRequest = createSelector(
  selectBillingSetupRequests,
  s => s.addPartner
);

export const selectFetchPartnerListIsNotStarted = createSelector(
  selectFetchPartnerListRequest,
  s => !!(s.loading === 'idle')
);

export const selectFetchPartnerListIsLoading = createSelector(
  selectFetchPartnerListRequest,
  s => !!(s.loading === 'pending')
);

export const selectFetchPartnerListIsCompleted = createSelector(
  selectFetchPartnerListRequest,
  s => !!(s.loading === 'succeeded')
);

export const selectFetchPartnerListIsFailed = createSelector(
  selectFetchPartnerListRequest,
  s => !!(s.loading === 'failed')
);

export const selectFetchPartnerListError = createSelector(
  selectFetchPartnerListRequest,
  s => s.error
);

export const selectDeletePartnerIsNotStarted = createSelector(
  selectDeletePartnerRequest,
  s => !!(s.loading === 'idle')
);

export const selectDeletePartnerIsLoading = createSelector(
  selectDeletePartnerRequest,
  s => !!(s.loading === 'pending')
);

export const selectDeletePartnerIsCompleted = createSelector(
  selectDeletePartnerRequest,
  s => !!(s.loading === 'succeeded')
);

export const selectDeletePartnerIsFailed = createSelector(
  selectDeletePartnerRequest,
  s => !!(s.loading === 'failed')
);

export const selectDeletePartnerError = createSelector(
  selectDeletePartnerRequest,
  s => s.error
);

export const selectAddPartnerIsNotStarted = createSelector(
  selectAddPartnerRequest,
  s => !!(s.loading === 'idle')
);

export const selectAddPartnerIsLoading = createSelector(
  selectAddPartnerRequest,
  s => !!(s.loading === 'pending')
);

export const selectAddPartnerIsCompleted = createSelector(
  selectAddPartnerRequest,
  s => !!(s.loading === 'succeeded')
);

export const selectAddPartnerIsFailed = createSelector(
  selectAddPartnerRequest,
  s => !!(s.loading === 'failed')
);

export const selectAddPartnerError = createSelector(
  selectAddPartnerRequest,
  s => s.error
);

export const selectPartnerListData = createSelector(
  selectBillingSetup,
  s => s.data
);
