import { AppException } from '../utils';
import { API_BASEURL } from '../config';

const dashboardAPI = {
  async fetchStats() {
    try {
      const response = await fetch(`${API_BASEURL}/api/billing/dashboard`, {
        method: 'GET',
        credentials: 'include',
      });
      if (response.ok) {
        const text = await response.text(); // Parse it as text
        const data = JSON.parse(text);
        return data;
      } else {
        if (response.status === 403) {
          throw new AppException('UNAUTHORIZED_ERROR');
        }
        throw new AppException('FETCH_ERROR');
      }
    } catch (err) {
      if (err instanceof AppException) {
        throw err;
      }
      return {};
    }
  },
};

export default dashboardAPI;
