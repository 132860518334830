import { AppException } from '../../utils';
import { API_BASEURL } from '../../config';

export const userInfoAPI = {
  async getUserInfo() {
    try {
      const url = `${API_BASEURL}/api/userinfo`;
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
      });
      if (response.ok) {
        const text = await response.text(); // Parse it as text
        const data = JSON.parse(text);
        return data;
      } else {
        const data = JSON.parse(await response.text());
        if (response.status === 403) {
          throw new AppException('UNAUTHORIZED_ERROR');
        }
        throw new AppException(data.error || 'Unable to fetch userinfo');
      }
    } catch (err) {
      if (err instanceof AppException) {
        throw err;
      }
      return {};
    }
  },
};

export default userInfoAPI;
