// This must be the first line in src/index.js
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import fetchIntercept from 'fetch-intercept';
import './index.scss';
import App from './App';
import configureStore from './store/configureStore';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { CSRF_TOKEN_CREATE_ENDPOINT, API_BASEURL } from './config';
import * as conf from './config';
import { getCsrfToken, clearCsrfToken } from './utils/csrf-token';
console.log({ conf });

(async () => {
  await clearCsrfToken();
})();

// Intercept API requests to restrict CSRF
fetchIntercept.register({
  request: async function (url, config) {
    if (!config) return [url, config];
    const httpMethod = config?.method || '';
    if (
      httpMethod !== 'POST' &&
      httpMethod !== 'PUT' &&
      httpMethod !== 'DELETE'
    )
      return [url, config];
    console.log('csrf http method barrier passed');
    if (url === CSRF_TOKEN_CREATE_ENDPOINT) return [url, config];
    console.log('csrf create endpoint barrier passed');
    if (!url.includes(API_BASEURL)) return [url, config];
    console.log('csrf API_BASEURL barrier passed');
    console.log({
      url,
      CSRF_TOKEN_CREATE_ENDPOINT,
      API_BASEURL,
      conf: config,
      method: config?.method,
      headers: config?.headers,
    });
    const csrfToken = await getCsrfToken();
    console.log({ csrfToken });
    config.headers = config.headers || {};
    config.headers['x-csrf-token'] = csrfToken;
    return [url, config];
  },

  requestError: function (error) {
    // Called when an error occured during another 'request' interceptor call
    return Promise.reject(error);
  },

  response: function (response) {
    // Modify the reponse object
    return response;
  },

  responseError: function (error) {
    // Handle an fetch error
    return Promise.reject(error);
  },
});

const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={ store }>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
