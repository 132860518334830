import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import dashboardAPI from './dashboardAPI';

export const fetchDashboardStats = createAsyncThunk(
  'dashboard/fetchStats',
  async (_, { getState, rejectWithValue }) => {
    const { loading } = getState().dashboardStats;
    if (loading !== 'pending') {
      return;
    }
    try {
      const response = await dashboardAPI.fetchStats();
      return response.content;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  data: null,
  loading: 'idle',
  error: null,
};

export const slice = createSlice({
  name: 'dashboardStats',
  initialState,
  reducers: {
    resetDashboardStats(state) {
      state.data = null;
      state.error = null;
      state.loading = 'idle';
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchDashboardStats.pending, (state, _action) => {
      if (state.loading === 'idle' || state.loading === 'succeeded') {
        state.loading = 'pending';
      }
    });
    builder.addCase(fetchDashboardStats.fulfilled, (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'succeeded';
        state.data = action.payload;
      }
    });
    builder.addCase(fetchDashboardStats.rejected, (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'failed';
        state.error = action.error;
      }
    });
  },
});

const reducer = slice.reducer;
export default reducer;

export const { resetDashboardStats } = slice.actions;

export const selectDashboardStats = state => state.dashboardStats;

export const selectIsNotStarted = createSelector(
  selectDashboardStats,
  s => !!(s.loading === 'idle')
);

export const selectIsLoading = createSelector(
  selectDashboardStats,
  s => !!(s.loading === 'pending')
);

export const selectIsCompleted = createSelector(
  selectDashboardStats,
  s => !!(s.loading === 'succeeded')
);

export const selectIsFailed = createSelector(
  selectDashboardStats,
  s => !!(s.loading === 'failed')
);

export const selectDashboardStatsError = createSelector(
  selectDashboardStats,
  s => s.error
);

export const selectDashboardStatsData = createSelector(
  selectDashboardStats,
  s => s.data
);
