import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { navItems } from '../../routes';
import { selectAppSettingsData } from '../../core/reducers/appSettingsSlice';
import {
  selectUserRoles,
  selectIsPartner,
  selectIsAdpBillingAdmin,
  selectIsAdpAccountsReceivable,
} from '../../core/reducers/authSlice';
import { SfcShellLeftNav } from '@synerg/react-components/dist/sfc-components';

const LeftNavbar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [allowedNavItems, setallowedNavItems] = useState([]);

  const appSettingsData = useSelector(selectAppSettingsData);
  const userRoles = useSelector(selectUserRoles);
  const isPartner = useSelector(selectIsPartner);
  const isAdpBillingAdmin = useSelector(selectIsAdpBillingAdmin);
  const isAdpAccountsReceivable = useSelector(selectIsAdpAccountsReceivable);

  useEffect(() => {
    // eslint-disable-next-line no-useless-return
    if (!(isPartner || isAdpBillingAdmin || isAdpAccountsReceivable)) return;

    const filteredByRole = navItems.filter(item => {
      if (item.id === 'file-history') {
        if (!appSettingsData?.bulkUpdateSettings) {
          return false;
        }

        return true;
      }
      if (!item.allowedRoles) return item;
      let allowed = false;
      for (const allowedRole of item.allowedRoles) {
        if (userRoles.includes(allowedRole)) {
          allowed = true;
          break;
        }
        continue;
      }
      if (allowed) return item;
      return false;
    });
    setallowedNavItems(filteredByRole);
  }, [
    userRoles,
    isPartner,
    isAdpBillingAdmin,
    isAdpAccountsReceivable,
    appSettingsData?.bulkUpdateSettings,
  ]);

  const getActiveLink = () => {
    const activeLink = navItems.find(route => route.pathname === pathname);

    // TODO: If no return we should redirect
    return activeLink;
  };

  return (
    <SfcShellLeftNav
      slot="nav"
      role="navigation"
      menuProvider={ allowedNavItems }
      activeItem={ getActiveLink() }
      onNavigate={ event => {
        const { detail } = event;
        navigate(detail.pathname);
        event.preventDefault();
      } }
      class="py-3"
    />
  );
};

export default LeftNavbar;
