import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAppSettings,
  selectIsNotStarted,
} from '../../core/reducers/appSettingsSlice';
import { selectIsLoggedIn } from '../../core/reducers/authSlice';

export const AppSettings = () => {
  const dispatch = useDispatch();
  const isNotStarted = useSelector(selectIsNotStarted);
  const isLoggedIn = useSelector(selectIsLoggedIn);

  useEffect(() => {
    if (isNotStarted && isLoggedIn) {
      dispatch(fetchAppSettings());
    }
  }, [dispatch, isNotStarted, isLoggedIn]);

  return <></>;
};

export default AppSettings;
