import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import userInfoAPI from '../apis/userInfoAPI';
import {
  ROLE_PARTNER,
  ROLE_ADP_ACCOUNTS_RECEIVABLE,
  ROLE_ADP_BILLING_ADMIN,
} from '../constants';

export const fetchUserInfo = createAsyncThunk(
  'auth/userInfo',
  async (_, { rejectWithValue }) => {
    try {
      const response = await userInfoAPI.getUserInfo();
      return response.content;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const { reducer } = createSlice({
  name: 'auth',
  initialState: {
    loading: 'idle',
    id: null,
    expiresAt: null,
    timeLeftForExpiry: null,
    user: {
      roles: [],
      roleList: [],
    },
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchUserInfo.fulfilled, (state, action) => {
      const { payload } = action;
      if (payload) {
        try {
          state.loading = 'succeeded';
          state.user = payload;
          state.id = payload.sub;

          const expiresAt = payload.ttl; // add 1hr to issued time
          state.expiresAt = expiresAt;
          const expiresAtTimestamp = expiresAt;
          const expiresAtTime =
            expiresAtTimestamp > 0
              ? new Date(expiresAtTimestamp).getTime()
              : Date.now();
          const timeLeftForExpiry = expiresAtTime - Date.now();
          state.timeLeftForExpiry =
            timeLeftForExpiry > 0 ? timeLeftForExpiry : 0;
        } catch (error) {
          state.loading = 'failed';
          state.user = null;
          state.id = null;
          state.expiresAt = null;
          state.timeLeftForExpiry = null;
        }
      } else {
        state.loading = 'failed';
        state.user = null;
        state.id = null;
        state.expiresAt = null;
        state.timeLeftForExpiry = null;
      }
    });
    builder.addCase(fetchUserInfo.rejected, (state, action) => {
      state.loading = 'failed';
      state.user = null;
      state.id = null;
      state.expiresAt = null;
      state.timeLeftForExpiry = null;
    });
  },
});

export default reducer;

export const selectAuth = state => state.auth;

export const selectIsNotStarted = createSelector(
  selectAuth,
  s => !!(s.loading === 'idle')
);

export const selectIsCompleted = createSelector(
  selectAuth,
  s => !!(s.loading === 'succeeded')
);

export const selectIsFailed = createSelector(
  selectAuth,
  s => !!(s.loading === 'failed')
);

export const selectIsLoggedIn = createSelector(selectAuth, s => !!s.id);

export const selectUserId = createSelector(selectAuth, s => s.id);

export const selectExpiresAt = createSelector(selectAuth, s => s.expiresAt);

export const selectTimeLeftForExpiry = createSelector(
  selectAuth,
  s => s.timeLeftForExpiry
);

export const selectUser = createSelector(selectAuth, s => s?.user);

export const selectUserFullName = createSelector(selectUser, s => s.name);

export const selectUserOrgName = createSelector(
  selectUser,
  s => s.organizationName
);

export const selectUserRoles = createSelector(selectUser, s => s?.roles);

export const selectIsPartner = createSelector(selectUserRoles, s =>
  s.split(' ').includes(ROLE_PARTNER)
);

export const selectIsAdpBillingAdmin = createSelector(selectUserRoles, s =>
  s.split(' ').includes(ROLE_ADP_BILLING_ADMIN)
);

export const selectIsAdpAccountsReceivable = createSelector(
  selectUserRoles,
  s => s.split(' ').includes(ROLE_ADP_ACCOUNTS_RECEIVABLE)
);
