import React from 'react';
import { SfcShellAppBar } from '@synerg/react-components/dist/sfc-components';
import Avatar from '../avatars/Avatar';

const Header = () => {
  return (
    <SfcShellAppBar slot="app-bar" size="m" class="flex flex-row">
      <div className="branding-area font-semibold text-lg">
        Marketplace Revenue Share
      </div>
      <Avatar />
    </SfcShellAppBar>
  );
};

export default Header;
