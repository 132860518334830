import { CSRF_TOKEN_CREATE_ENDPOINT } from '../config';
export async function getCsrfToken() {
  const csrfKey = `rev_share_csrf_token_${sessionStorage.getItem(
    'persona_role'
  )}`;
  const value = sessionStorage.getItem(csrfKey);
  if (value) return value;
  const { csrfToken } = await (
    await fetch(CSRF_TOKEN_CREATE_ENDPOINT, { credentials: 'include' })
  ).json();
  sessionStorage.setItem(csrfKey, csrfToken);
  return csrfToken;
}

export async function clearCsrfToken() {
  console.log('Clearing csrf token from session storage');
  const csrfKey = `rev_share_csrf_token_${sessionStorage.getItem(
    'persona_role'
  )}`;
  sessionStorage.removeItem(csrfKey);
}
