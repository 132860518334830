/* eslint-disable no-unused-vars */
const Dashboard = {
  id: 'dashboard',
  title: 'Dashboard',
  pathname: '/',
  icon: 'home',
};

const ReferralSales = {
  id: 'referral-sales',
  title: 'Referral sales',
  pathname: '/sales',
  icon: 'payments',
};

const BillingReview = {
  id: 'billing-review',
  title: 'Monthly approval',
  pathname: '/review',
  icon: 'media-file',
};

const BillingHistory = {
  id: 'billing-history',
  title: 'Invoice history',
  pathname: '/history',
  icon: 'status-bookmarked',
};

const BillingUsers = {
  id: 'billing-users',
  title: 'Billing users',
  pathname: '/users',
  icon: 'user-avatar',
};

const BillingSetup = {
  id: 'billing-setup',
  title: 'Billing setup',
  pathname: '/setup',
  icon: 'action-view-settings',
  allowedRoles: ['rsAdpAccountsReceivable', 'rsAdpBillingAdmin'],
};

const ChangeHistory = {
  id: 'change-history',
  title: 'Audit trail',
  pathname: '/change',
  icon: 'action-history',
};

const Files = {
  id: 'file-history',
  title: 'Files',
  pathname: '/files',
  icon: 'media-folder',
};

const navItems = [
  Dashboard,
  // ReferralSales,
  BillingReview,
  BillingHistory,
  BillingSetup,
  // BillingUsers,
  ChangeHistory,
  Files,
];

export { navItems };
