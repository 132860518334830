import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import appSettingsAPI from '../apis/appSettingsAPI';

export const fetchAppSettings = createAsyncThunk(
  'appSettings/fetchAppSettings',
  async (_, { getState, rejectWithValue }) => {
    const { loading } = getState().appSettings;
    if (loading !== 'pending') {
      return;
    }
    try {
      const response = await appSettingsAPI.fetchAppSettings();
      return response.content;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  data: null,
  loading: 'idle',
  error: null,
};

export const slice = createSlice({
  name: 'appSettings',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchAppSettings.pending, (state, _action) => {
      if (state.loading === 'idle' || state.loading === 'succeeded') {
        state.loading = 'pending';
      }
    });
    builder.addCase(fetchAppSettings.fulfilled, (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'succeeded';
        state.data = action.payload;
      }
    });
    builder.addCase(fetchAppSettings.rejected, (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'failed';
        state.error = action.error;
      }
    });
  },
});

const reducer = slice.reducer;
export default reducer;

export const selectAppSettings = state => state.appSettings;

export const selectIsNotStarted = createSelector(
  selectAppSettings,
  s => !!(s.loading === 'idle')
);

export const selectIsLoading = createSelector(
  selectAppSettings,
  s => !!(s.loading === 'pending')
);

export const selectIsCompleted = createSelector(
  selectAppSettings,
  s => !!(s.loading === 'succeeded')
);

export const selectIsFailed = createSelector(
  selectAppSettings,
  s => !!(s.loading === 'failed')
);

export const selectAppSettingsError = createSelector(
  selectAppSettings,
  s => s.error
);

export const selectAppSettingsData = createSelector(
  selectAppSettings,
  s => s.data
);
