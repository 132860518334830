import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  SdfAvatar,
  SdfButton,
  SdfCard,
  SdfMiniCard,
  SdfLink,
  SdfIcon,
} from '@synerg/react-components';
import { selectUser } from '../../core/reducers/authSlice';
import * as styles from './Avatar.styles';
import { API_BASEURL } from '../../config';
import { clearCsrfToken } from '../../utils/csrf-token';

const Avatar = () => {
  const user = useSelector(selectUser);
  const [fullName, setFullName] = useState('');
  const [initials, setInitials] = useState('');
  const [displayRole, setDisplayRole] = useState('');
  const [showingAvatarMenu, setShowingAvatarMenu] = useState(false);

  const menuRef = useRef(null);
  const avatarRef = useRef(null);

  const getDisplayRole = user => {
    let pendoDisplayRole = '';
    if (user.roles?.includes('rsPartner')) {
      pendoDisplayRole = 'Partner';
    }
    if (user.roles?.includes('rsAdpAccountsReceivable')) {
      pendoDisplayRole = 'Accounts Receivable';
    }
    if (user.roles?.includes('rsAdpBillingAdmin')) {
      pendoDisplayRole = 'Billing Admin';
    }
    return pendoDisplayRole;
  };
  useEffect(() => {
    if (user) {
      const firstInitial = user.given_name.charAt(0).toUpperCase();
      const firstName = firstInitial + user.given_name.slice(1);
      const lastInitial = user.family_name.charAt(0).toUpperCase();
      const lastName = lastInitial + user.family_name.slice(1);

      setInitials(`${firstInitial}${lastInitial}`);
      setFullName(`${firstName} ${lastName}`);

      setDisplayRole(getDisplayRole(user));
    }
  }, [user]);

  useEffect(() => {
    document.body.addEventListener('click', onClickOutside);
    return () => document.removeEventListener('click', onClickOutside);
  }, []);

  useEffect(() => {
    const pendoInitialize = () => {
      const pendo = window.pendo;

      if (pendo.initialize && user) {
        pendo.initialize({
          visitor: {
            id: user.associateOID,
            revShareRole: getDisplayRole(user),
          },
          account: {
            id: user.adpWebID,
          },
        });
      }
    };
    pendoInitialize();
  }, [user]);

  const onClickOutside = e => {
    const element = e.target;
    if (avatarRef.current && avatarRef.current.contains(element)) {
      return;
    }
    if (menuRef.current && !menuRef.current.contains(element)) {
      return setShowingAvatarMenu(false);
    }
  };

  return (
    <div className={ styles.avatarContainer } slot="icons">
      <div className={ styles.avatarButtonContainer } ref={ avatarRef }>
        <SdfButton
          variant="text"
          onClick={ () => setShowingAvatarMenu(!showingAvatarMenu) }
        >
          <SdfAvatar initials={ initials } />
        </SdfButton>
        <p className={ styles.avatarName }>{ fullName }</p>
      </div>

      <div
        ref={ menuRef }
        className={ showingAvatarMenu ? '' : styles.avatarSignOutCardHidden }
        style={ { position: 'relative', top: '-.25em' } }
      >
        <SdfCard>
          <SdfMiniCard>
            <SdfAvatar initials={ initials } size="lg" slot="graphic" />
            <span className={ styles.defaultText } slot="title">
              { fullName }
            </span>
            <span className={ styles.defaultText } slot="subtitle">
              { displayRole }
            </span>
          </SdfMiniCard>

          <hr className={ styles.avatarSignOutCardBar } />

          <SdfLink
            href={ `${API_BASEURL}/logout` }
            onClick={ async () => {
              await clearCsrfToken();
            } }
            action="standard"
            title="signout"
            className={ styles.avatarSignOutLink }
          >
            <SdfIcon icon="action-logout" />
            <span>Sign out</span>
          </SdfLink>
        </SdfCard>
      </div>
    </div>
  );
};

export default Avatar;
