import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { SdfPageLayout, SdfBusyIndicator } from '@synerg/react-components';
import { SfcShell } from '@synerg/react-components/dist/sfc-components';

import LeftNavbar from './componets/navbars/LeftNavbar';
import Header from './componets/menus/Header';
import AuthHandler from './componets/auth/AuthHandler';
import FeedbackFooter from './componets/footers/FeedbackFooter';
import AppSettings from './componets/settings/AppSettings';

// TODO: We should have a index Route and then another Route inside

const AppRoutes = React.lazy(() => import('./componets/routes/BaseRoutes.jsx'));

export const App = () => {
  return (
    <BrowserRouter>
      <div id="app" className="app">
        <AuthHandler>
          <SfcShell>
            <Header />
            <LeftNavbar />
            <SdfPageLayout>
              <Suspense fallback={ <SdfBusyIndicator /> }>
                <AppRoutes />
              </Suspense>
            </SdfPageLayout>
          </SfcShell>
          <FeedbackFooter />
        </AuthHandler>
        <AppSettings />
      </div>
    </BrowserRouter>
  );
};

export default App;
