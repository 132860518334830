import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { reduxBatch } from '@manaflair/redux-batch';
import monitorReducersEnhancer from './enhancers/monitorReducers';
import rootReducer from './reducers';
import { NODE_ENV } from '../config';

export default function configureAppStore(preloadedState) {
  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(logger),
    devTools: NODE_ENV !== 'production',
    preloadedState,
    enhancers: [monitorReducersEnhancer, reduxBatch],
  });

  if (NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
  }

  return store;
}
