import { classnames } from 'tailwindcss-classnames';

export const avatarContainer = classnames('flex', 'flex-col', 'items-end');

export const avatarButtonContainer = classnames('flex', 'flex-col');

export const avatarName = classnames('m-1');

export const avatarSignOutCardHidden = classnames('invisible');

export const avatarSignOutCardBar = classnames(
  'mb-2',
  '-mx-6',
  'border-0',
  'border-t-1',
  'border-solid',
  'border-dark',
  'border-opacity-50'
);

export const avatarSignOutLink = classnames('text-default', 'text-lg', 'mt-2');

export const defaultText = classnames('text-default');
