import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import FileProcessAPI from './FileProcessAPI';

export const fetchFiles = createAsyncThunk(
  'fileDetails/fetchFiles',
  async (params, { getState, rejectWithValue }) => {
    const { loading } = getState().fileDetails.requests.fetchFiles;
    if (loading !== 'pending') {
      return;
    }
    try {
      const response = await FileProcessAPI.fetchFiles(params);
      return response;
    } catch (error) {
      return rejectWithValue({ error: error.massage });
    }
  }
);

export const fetchFileDetails = createAsyncThunk(
  'fileDetails/fetchFileDetails',
  async (fileProcessId, { getState, rejectWithValue }) => {
    try {
      const response = await FileProcessAPI.fetchFileDetails(fileProcessId);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  loading: 'idle',
  requests: {
    fetchFiles: {
      files: [],
      loading: 'idle',
      error: null,
    },
    fetchFileDetails: {
      fileDetails: {},
      loading: 'idle',
      error: null,
    },
  },
  error: null,
};

const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchFiles.pending, (state, _action) => {
      if (
        state.requests.fetchFiles.loading === 'idle' ||
        state.requests.fetchFiles.loading === 'succeeded'
      ) {
        state.requests.fetchFiles.loading = 'pending';
      }
    });
    builder.addCase(fetchFiles.fulfilled, (state, action) => {
      if (state.requests.fetchFiles.loading === 'pending') {
        state.requests.fetchFiles.loading = 'succeeded';
        state.requests.fetchFiles.files = action.payload;
      }
    });
    builder.addCase(fetchFiles.rejected, (state, action) => {
      if (state.requests.fetchFiles.loading === 'pending') {
        state.requests.fetchFiles.loading = 'failed';
        state.requests.fetchFiles.error = action.error;
      }
    });
    builder.addCase(fetchFileDetails.pending, (state, _action) => {
      state.requests.fetchFileDetails.loading = 'pending';
    });
    builder.addCase(fetchFileDetails.fulfilled, (state, action) => {
      state.requests.fetchFileDetails.fileDetails[
        action.payload?.bulkUpdateProcessId
      ] = action.payload;
    });
    builder.addCase(fetchFileDetails.rejected, (state, action) => {
      state.requests.fetchFileDetails.loading = 'failed';
      state.requests.fetchFileDetails.error = action.error;
    });
  },
});

export default filesSlice.reducer;

export const selectFiledata = state => state.fileDetails;

export const selectFileRequests = createSelector(
  selectFiledata,
  s => s.requests
);

export const selectFileListRequest = createSelector(
  selectFileRequests,
  s => s.fetchFiles?.files
);

export const getFileDetailsSelector = createSelector(
  selectFileRequests,
  s => s.fetchFileDetails?.fileDetails
);

export const getFileDetailsById = bulkUpdateId =>
  createSelector(getFileDetailsSelector, s => s[bulkUpdateId]);

export const selectFileListRequestIsLoading = createSelector(
  selectFileRequests,
  s => s.fetchFiles?.loading
);
