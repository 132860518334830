import { AppException } from '../utils';
import { API_BASEURL } from '../config';

const billingReviewAPI = {
  async getBillingSummary({ partnerOOID, invoiceId }) {
    try {
      let url = `${API_BASEURL}/api/billing/summary`;
      if (partnerOOID)
        url += `?partnerOOID=${partnerOOID}&invoiceId=${invoiceId}`;
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
      });
      if (response.ok) {
        const text = await response.text(); // Parse it as text
        const data = JSON.parse(text);
        return data;
      } else {
        const data = JSON.parse(await response.text());
        if (response.status === 403) {
          throw new AppException('UNAUTHORIZED_ERROR');
        }
        throw new AppException(data.error || 'Invoice not found.');
      }
    } catch (err) {
      if (err instanceof AppException) {
        throw err;
      }
      return {};
    }
  },
  async postLeadChanges({ invoiceId, leadId, payload }) {
    try {
      const response = await fetch(
        `${API_BASEURL}/api/billing/summary/${invoiceId}/leads/${leadId}`,
        {
          mode: 'cors',
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
        }
      );
      const text = await response.text(); // Parse it as text
      const data = JSON.parse(text);
      if (response.ok) {
        return data;
      } else {
        if (response.status === 403) {
          throw new AppException('UNAUTHORIZED_ERROR');
        } else if (response.status === 500) {
          throw new AppException('SERVER_ERROR', data.error);
        }
        throw new AppException('UNKNOWN_ERROR', data.error);
      }
    } catch (err) {
      if (err instanceof AppException) {
        throw err;
      }
      return {};
    }
  },
  async getBillingADPSummary() {
    try {
      const response = await fetch(`${API_BASEURL}/api/billing/review/`, {
        method: 'GET',
        credentials: 'include',
      });
      if (response.ok) {
        const text = await response.text(); // Parse it as text
        const data = JSON.parse(text);
        return data.content;
      } else {
        if (response.status === 403) {
          throw new AppException('UNAUTHORIZED_ERROR');
        }
        if (response.status === 404)
          throw new AppException('Invoice not found.');
        throw new AppException('FETCH_ERROR');
      }
    } catch (err) {
      if (err instanceof AppException) {
        throw err;
      }
      throw new Error('Unspecified API Error');
    }
  },
  async terminateLead({ invoiceId, leadId, comment }) {
    try {
      const response = await fetch(
        `${API_BASEURL}/api/billing/summary/${invoiceId}/leads/${leadId}`,
        {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            status: 'DELETED',
            comment,
          }),
        }
      );
      if (response.ok) {
        return;
      } else {
        if (response.status === 403) {
          throw new AppException('UNAUTHORIZED_ERROR');
        }
        throw new AppException('FETCH_ERROR');
      }
    } catch (err) {
      if (err instanceof AppException) {
        throw err;
      }
      return {};
    }
  },
  async unTerminateLead({ invoiceId, leadId, comment }) {
    try {
      const response = await fetch(
        `${API_BASEURL}/api/billing/summary/${invoiceId}/leads/${leadId}`,
        {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            status: 'Won',
            comment,
          }),
        }
      );
      if (response.ok) {
        return;
      } else {
        if (response.status === 403) {
          throw new AppException('UNAUTHORIZED_ERROR');
        }
        throw new AppException('FETCH_ERROR');
      }
    } catch (err) {
      if (err instanceof AppException) {
        throw err;
      }
      return {};
    }
  },
  async reviewOrApproveInvoice({ invoiceId, role, approved }) {
    try {
      const response = await fetch(
        `${API_BASEURL}/api/invoice/${invoiceId}/review`,
        {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            approved,
          }),
        }
      );
      if (response.ok) {
        return;
      } else {
        if (response.status === 403) {
          throw new AppException('UNAUTHORIZED_ERROR');
        }
        throw new AppException('FETCH_ERROR');
      }
    } catch (err) {
      if (err instanceof AppException) {
        throw err;
      }
      return {};
    }
  },
  async getChangeDetail(leadId) {
    try {
      const response = await fetch(
        `${API_BASEURL}/api/change-details/leads/${leadId}`,
        {
          method: 'GET',
          credentials: 'include',
        }
      );
      if (response.ok) {
        const text = await response.text(); // Parse it as text
        const data = JSON.parse(text);
        return data;
      } else {
        if (response.status === 403) {
          throw new AppException('UNAUTHORIZED_ERROR');
        }
        throw new AppException('FETCH_ERROR');
      }
    } catch (err) {
      if (err instanceof AppException) {
        throw err;
      }
      return {};
    }
  },
  async getFileUploadCountSummary({ partnerOOID }) {
    try {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth();
      const firstDay = new Date(year, month, 1).toISOString();
      const lastDay = new Date(year, month + 1, 0).toISOString();
      const url = `${API_BASEURL}/api/bulk-updates?partnerorgid=${partnerOOID}&from=${firstDay}&to=${lastDay}`;
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
      });
      if (response.ok) {
        const text = await response.text(); // Parse it as text
        const data = JSON.parse(text);
        return data;
      } else {
        const data = JSON.parse(await response.text());
        if (response.status === 403) {
          throw new AppException('UNAUTHORIZED_ERROR');
        }
        throw new AppException(data.error || 'Invoice not found.');
      }
    } catch (err) {
      if (err instanceof AppException) {
        throw err;
      }
      return {};
    }
  },
};

export default billingReviewAPI;
