import { combineReducers } from 'redux';
import authReducer from '../core/reducers/authSlice';
import appSettingsReducer from '../core/reducers/appSettingsSlice';
import auditTrailReducer from '../auditTrail/auditTrailSlice';
import billingUsersReducer from '../billingUsers/billingUsersSlice';
import billingReviewReducer from '../billingReview/billingReviewSlice';
import billingHistoryReducer from '../billingHistory/billingHistorySlice';
import billingSetupReducer from '../billingSetup/billingSetupSlice';
import dashboardStatsReducer from '../dashboard/dashboardSlice';
import filesReducer from '../files/FilesPageSlice';

export default combineReducers({
  auth: authReducer,
  appSettings: appSettingsReducer,
  auditTrail: auditTrailReducer,
  billingUsers: billingUsersReducer,
  billingReview: billingReviewReducer,
  billingHistory: billingHistoryReducer,
  billingSetup: billingSetupReducer,
  dashboardStats: dashboardStatsReducer,
  fileDetails: filesReducer,
});
