import { AppException } from '../utils';
import { API_BASEURL } from '../config';

const FileProcessAPI = {
  async fetchFiles(params) {
    let endPoint;
    try {
      if (params) {
        const { selectedPartner, fromDate, toDate } = params;
        endPoint = `/api/bulk-updates?partnerorgid=${selectedPartner}&from=${fromDate}&to=${toDate}`;
      } else {
        endPoint = `/api/bulk-updates`;
      }
      const response = await fetch(`${API_BASEURL}${endPoint}`, {
        method: 'GET',
        credentials: 'include',
      });
      if (response.ok) {
        const text = await response.text(); // Parse it as text
        const data = JSON.parse(text);
        return data;
      } else {
        if (response.status === 403) {
          throw new AppException('UNAUTHORIZED_ERROR');
        }
        throw new AppException('FETCH_ERROR');
      }
    } catch (err) {
      if (err instanceof AppException) {
        throw err;
      }
      return {};
    }
  },
  async fetchFileDetails(fileProcessId) {
    try {
      const response = await fetch(
        `${API_BASEURL}/api/bulk-updates/${fileProcessId}`,
        {
          method: 'GET',
          credentials: 'include',
        }
      );
      if (response.ok) {
        const text = await response.text(); // Parse it as text
        const data = JSON.parse(text);
        return data;
      } else {
        if (response.status === 403) {
          throw new AppException('UNAUTHORIZED_ERROR');
        }
        throw new AppException('FETCH_ERROR');
      }
    } catch (err) {
      if (err instanceof AppException) {
        throw err;
      }
      return {};
    }
  },
};

export default FileProcessAPI;
